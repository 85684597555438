import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import useSiteContext from './SiteContext';

const Auth = props => {
  return (
    <div>
      <p>Logging in...</p>
    </div>
  );
};

export default Auth;
